import React, { useState, useEffect } from "react";
import classnames from "classnames";
import sendEmail from "../api/sendEmail";
import Button from "./Button";
import { Dictionary } from "../models";

interface InputState {
  value: string;
  touched: boolean;
  validator: (val: string, touched: boolean) => boolean;
}

function inputValidator(val: string, touched: boolean) {
  if (val.trim() === "" || !touched) {
    return false;
  }

  return true;
}

function emailValidator(email: string, touched: boolean) {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!regex.test(email.toLowerCase())) {
    return false;
  }

  return inputValidator(email, touched);
}

const defaultFormState: Dictionary<InputState> = {
  name: {
    value: "",
    touched: false,
    validator: inputValidator
  },
  email: {
    value: "",
    touched: false,
    validator: emailValidator
  },
  message: {
    value: "",
    touched: false,
    validator: inputValidator
  }
};

const selectOptions = [
  "I have some great ideas for Cali Skills.",
  "Something isn't working for me.",
  "I'd like to contribute to Cali Skills."
];

function ContactUsForm() {
  const [formState, setFormState] = useState(defaultFormState);
  const [isValid, setValidState] = useState(false);
  const [subjectValue, setSubjectValue] = useState(selectOptions[0]);
  const [hasSubmitted, setSubmissionState] = useState(false);

  function handleChange(newValue: string, inputKey: string) {
    const updatedState = {
      ...formState,
      [inputKey]: {
        ...formState[inputKey],
        value: newValue,
        touched: true
      }
    };

    return setFormState(updatedState);
  }

  function validateInput(inputState: InputState) {
    const { validator, value, touched } = inputState;

    return validator(value, touched);
  }

  useEffect(() => {
    let isValid = true;

    Object.keys(formState).forEach(input => {
      const currentInput = formState[input];
      const isInputValid = validateInput(currentInput);

      if (!isInputValid) {
        isValid = false;
      }
    });

    setValidState(isValid);
  }, [formState, setValidState]);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!isValid) return null;

    const { name, email, message } = formState;

    const input = {
      name: name.value,
      email: email.value.toLowerCase(),
      message: message.value,
      subject: subjectValue
    };

    await sendEmail(input);
    setSubmissionState(true);
  }
  return (
    <form
      data-testid="contact-us-form"
      onSubmit={handleSubmit}
      className="ContactUs__form"
    >
      <label className="ContactUs__label" htmlFor="name">
        Name:
      </label>
      <input
        id="name"
        className="ContactUs__input"
        required
        type="text"
        value={formState.name.value}
        onChange={({ target }) => handleChange(target.value, "name")}
      />
      <div className="ContactUs__label-container">
        <label className="ContactUs__label" htmlFor="email">
          Email:
        </label>
        <span
          className={classnames("ContactUs__email-warning", {
            "ContactUs__email-warning--visible":
              !validateInput(formState.email) && formState.email.touched
          })}
          aria-live="polite"
        >
          *Please enter a valid email
        </span>
      </div>
      <input
        id="email"
        className="ContactUs__input"
        required
        type="email"
        value={formState.email.value}
        onChange={({ target }) => handleChange(target.value, "email")}
      />
      <label className="ContactUs__label" htmlFor="subject">
        Subject:
      </label>
      <select
        id="subject"
        className="ContactUs__select"
        onChange={({ target }) => setSubjectValue(target.value)}
        value={subjectValue}
      >
        {selectOptions.map(option => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </select>
      <label className="ContactUs__label" htmlFor="message">
        Message:
      </label>
      <textarea
        id="message"
        className="ContactUs__input ContactUs__text-area"
        required
        value={formState.message.value}
        onChange={({ target }) => handleChange(target.value, "message")}
      />
      <Button disabled={!isValid || hasSubmitted} type="submit">
        Send Message
      </Button>
      {hasSubmitted && (
        <p>
          Your message has been sent successfully, thanks for getting in touch.
        </p>
      )}
    </form>
  );
}

export default ContactUsForm;
