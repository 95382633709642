import React from "react";
import Layout from "../components/layouts/Layout";
import SEO from "../components/SEO";
import Header from "../components/Header";
import "../styles/ContactUs.css";
import ContactUsForm from "../components/ContactUsForm";

function ContactUs() {
  return (
    <Layout>
      <SEO path="/contact-us/" title="Contact Cali Skills" />
      <Header title="Contact Us" />
      <p>
        We want Cali Skills to be the very best it can be, and the best
        way for us to get there is by hearing the thoughts and comments of the
        calisthenics community.
      </p>
      <p>
        If there's a feature you'd like to see or if you feel there are areas
        for improvement, then please get in touch.
      </p>
      <ContactUsForm />
    </Layout>
  );
}

export default ContactUs;
